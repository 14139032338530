<template>
    <div class="reply">
        <div class="content">
            <div class="line_01">常见问题</div>
            <div>
                <el-table v-if="list.length!=0" :data="list" border :header-cell-style="{background:'#EFD8D7'}">
                    <el-table-column prop="title" label="标题" align="center" />
                    <el-table-column label="操作" align="center" width="250">
                        <template #default="scope">
                            <div class="operation" @click="$router.push({path:'/common_problem',query:{id:scope.row.id}})">查看</div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页管理 -->
                <Page v-if="list.length!=0" :pageSize="page_size" :total="total" :current="page" @change="pageChange"/>
                <div class="t-center" style="padding:40px 0px;" v-else>
                    <img src="shexie/img/nodata1.png" alt="">
                    <div>暂无数据</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {articles} from '@/api/news.js'
export default {
    data() {
        return {
            page_size:10,   //每页条数
            total:0,        //总条数
            page:1,        //当前页
            list:[]
        };
    },
    created() {
        this.getList()
    },
    mounted() {

    },
    methods: {
        getList(){
            articles({cate_id:this.$route.query.id,page:this.page,page_size:this.page_size}).then(res=>{
                if(res.code==200){
                    this.list = res.data.data
                    this.total = res.data.total
                }else{
                    this.list = []
                    this.total = 0
                }
            })
        },
        //分页
        pageChange(val){
            this.page  = val;
            this.getList()
        },
    }
};
</script>

<style scoped lang="scss">
    .reply{
        margin: 20px auto;
        .content{
            margin-top: 40px;
            .line_01{ 
                width:100px;
                padding: 0 20px 0;
                margin:0px auto 30px;  
                line-height: 2px;  
                color: #CD0000;
                font-weight: bold;
                border-left: 80px solid #CD0000;  
                border-right: 80px solid #CD0000;  
                text-align: center;  
            }
            ::v-deep .el-table th.el-table__cell.is-leaf{
                color: #CD0000;
            }
        }
    }
</style>
